import React, { useState, useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import Particle from "../Particle";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const certifs = [
  "https://raw.githubusercontent.com/amhyou/Resume/095a35059e7d863d381b9376caa67d48aa22c245/AzureAdministrator.pdf",
  "https://raw.githubusercontent.com/amhyou/Resume/1e41bd5f1b150e1305c4d4d7fd00bec148f40521/Devops_IBM_Coursera.pdf",
  "https://raw.githubusercontent.com/amhyou/Resume/8e2fb8df3717d48efd2d4595d76a374d7e9014a4/AzureDataScience.pdf",
  "https://raw.githubusercontent.com/amhyou/Resume/8e2fb8df3717d48efd2d4595d76a374d7e9014a4/FourthPlaceContestant439738.pdf"
]

function Certif() {
  const [width, setWidth] = useState(1200);

  useEffect(() => {
    setWidth(window.innerWidth);
  }, []);

  return (
    <div>
      <Container fluid className="resume-section">
        <Particle />

        {
          certifs?.map((cert, key) => {
            return (
              <Row className="resume" key={key}>
                <Document file={cert} className="d-flex justify-content-center">
                  <Page pageNumber={1} scale={width > 786 ? 1.7 : 0.6} />
                </Document>
              </Row>
            )
          })
        }



      </Container>
    </div>
  );
}

export default Certif;
